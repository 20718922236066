<template>
  <div class="recharge-view">
    <el-tabs v-model="activeName">
      <el-tab-pane label="充值" name="first">
        <el-form ref="postForm" :rules="rules" :model="postData" label-width="auto">
          <el-form-item label="充值驾校">
            {{ $props.row.drvSchoolForShort }}
          </el-form-item>
          <el-form-item prop="orderFlowAmount" label="充值金额">
            <el-input v-model="postData.orderFlowAmount" placeholder="请输入充值金额"></el-input>
          </el-form-item>
          <el-form-item prop="businessNo" label="业务编号">
            <el-input v-model="postData.businessNo" placeholder="请输入业务编号"></el-input>
          </el-form-item>
          <el-form-item prop="payNo" label="交易流水号">
            <el-input v-model="postData.payNo" placeholder="请输入交易流水号"></el-input>
          </el-form-item>
          <el-form-item prop="payer" label="付款人">
            <el-input v-model="postData.payer" placeholder="请输入付款人"></el-input>
          </el-form-item>
          <el-form-item prop="bank" label="付款开户行">
            <el-input v-model="postData.bank" placeholder="请输入付款开户行"></el-input>
          </el-form-item>
          <el-form-item prop="voucherPath" label="充值凭证">
            <el-button type="primary" @click="uploadImgHandler">上传凭证</el-button>
            <div v-if="postData.licenseFileName" style="padding: 10px 0">
              已选文件 {{ postData.licenseFileName }} <el-link type="primary" @click="removeLicenseFile">[删除]</el-link>
            </div>
          </el-form-item>
        </el-form>
      </el-tab-pane>

      <el-tab-pane label="充值记录" name="second">
        <el-table :data="tableData" border v-loading="tableLoading">
          <el-table-column prop="orderFlowNo" label="流水号" width="150">
            <template slot-scope="scope">
              <el-link type="primary" @click="openLog(scope.row)">{{ scope.row.orderFlowNo }}</el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="creationTime"
            label="创建时间"
            width="160"
            :formatter="$formatter.date(true)"></el-table-column>
          <el-table-column prop="orderFlowAmount" label="充值金额(元)"></el-table-column>
          <el-table-column prop="operator" label="操作人员"></el-table-column>
        </el-table>
        <el-pagination @current-change="handleCurrentChange" layout="prev, pager, next" :total="total"> </el-pagination>
      </el-tab-pane>
    </el-tabs>

    <div class="admin-dialog-btns">
      <el-button type="primary" @click="submitHandler" :loading="loading">确认</el-button>
      <el-button
        @click="
          () => {
            this.$emit('end');
          }
        ">
        取消
      </el-button>
    </div>
  </div>
</template>

<script>
import {drvSchoolRecharge, systemUploadImg, rechargeFlowGetList} from '../../api/index';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      rules: {
        orderFlowAmount: [this.$rules.required], // 充值金额
        businessNo: [this.$rules.required], // 业务编号
        payNo: [this.$rules.required], // 交易流水号
        payer: [this.$rules.required], // 付款人
        bank: [this.$rules.required], // 开户银行
        voucherPath: [this.$rules.required], // 凭证地址
      },
      loading: false,
      tableLoading: false,
      activeName: 'first',
      postData: {
        drvSchoolCode: '', // 驾校代码
        orderFlowAmount: '', // 充值金额
        businessNo: '', // 业务编号
        payNo: '', // 交易流水号
        payer: '', // 付款人
        bank: '', // 开户银行
        voucherPath: '', // 凭证地址
        licenseFile: '',
        licenseFileName: '',
      },
      tableData: [],
      tableQuery: {
        drvSchoolCode: '',
        page: 1,
        size: 10,
      },
      total: 0,
    };
  },
  watch: {
    activeName() {
      if (this.activeName == 'second') {
        this.tableQuery.page = 1;
        this.tableQuery.size = 10;
        this.getRechargeList();
      }
    },
  },
  created() {
    this.postData.drvSchoolCode = this.$props.row.drvSchoolCode;
    this.tableQuery.drvSchoolCode = this.$props.row.drvSchoolCode;
  },
  methods: {
    // 充值记录打开
    async openLog(row) {
      let vNode = this.$createElement((await import('./recharge-log.vue')).default, {
        key: Math.random(),
        props: {
          row: row,
        },
        on: {},
      });

      this.$adminDialog(vNode, {
        title: '充值记录',
        width: '600px',
      });
    },

    uploadImgHandler() {
      let input = document.createElement('input');
      input.type = 'file';
      input.onchange = (ev) => {
        this.$set(this.postData, 'licenseFile', ev.target.files[0]);
        this.$set(this.postData, 'licenseFileName', this.postData.licenseFile.name);
        let formData = new FormData();
        formData.append('file', ev.target.files[0]);
        systemUploadImg(formData).then((res) => {
          if (res.data.code == 0) {
            this.$set(this.postData, 'voucherPath', res.data.data.url);
          } else {
            this.$message.error(res.data.msg);
            return false;
          }
        });
        input.remove();
      };
      input.click();
    },

    // 删除文件
    removeLicenseFile() {
      this.$delete(this.postData, 'licenseFile');
      this.$delete(this.postData, 'licenseFileName');
      this.$delete(this.postData, 'voucherPath');
    },

    handleCurrentChange(val) {
      this.tableQuery.page = val;
      this.getRechargeList();
    },

    getRechargeList() {
      this.tableLoading = true;
      let tableQuery = {
        orderFlowType: 1,
      };
      Object.assign(tableQuery, this.tableQuery);
      rechargeFlowGetList(tableQuery).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    submitHandler() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          drvSchoolRecharge(this.postData).then((res) => {
            this.loading = false;
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              this.$emit('success');
              this.$emit('end');
            } else {
              this.$message.error(res.data.msg);
              return false;
            }
          });
        } else {
          this.activeName = 'first';
          this.$message.error('请填写正确信息！');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.recharge-view {
  .admin-dialog-btns {
    margin-top: 25px;
  }
}
</style>
